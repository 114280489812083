function initEmailValidation () {

	const ftrSubMessage = document.querySelector('.errorSubscribeMessage');
	const ftrSignUpValidation = document.querySelector('.errorEmailSignUpValidation');
	const txtEmailInput = document.querySelector('#txtEmailUpdateRequest');
	const ftrSpanMesage = document.querySelector('.emailErrorValidationMessage');

	// Footer email sign up functionality
	$('#emailSubscribeBtnError').click(function () {

		if ($(this.form).valid()) {

			const objEmail = $('#txtEmailUpdateRequest').val();

			if (objEmail !== null) {

				const email = objEmail.replace(/</g, '').replace(/>/g, '');
				if (email.length > 0) {

					const URL = `https://${document.domain}/account/email/`;
					window.location = `${URL}?txtGetEmailUpdates=${email}`;

				}

			}

		}

	});

	window.initFormValidation(true, '#errorEmailSubscribe form', {
		errorElement: 'span',
		success: 'valid',
		invalidHandler (form, validator) {

			const errors = validator.numberOfInvalids();

			if (errors && txtEmailInput && ftrSubMessage && ftrSignUpValidation) {

				if (txtEmailInput.value.length === 0) {

					ftrSubMessage.classList.add('hidden');
					ftrSignUpValidation.classList.remove('hidden');
					validator.errorList[0].element.focus();

				} else {

					ftrSubMessage.classList.remove('hidden');
					ftrSignUpValidation.classList.add('hidden');
					setTimeout(() => {

						ftrSpanMesage.tabIndex = 0;
						ftrSpanMesage.focus();

					}, 300);

				}

			}

		}
	});
	if (txtEmailInput) {

		txtEmailInput.addEventListener('input', () => {

			if (ftrSubMessage && ftrSignUpValidation) {

				ftrSubMessage.classList.add('hidden');
				setTimeout(() => {

					ftrSignUpValidation.classList.remove('hidden');

				}, 300);

			}

		});

	}

}

export default initEmailValidation;
