import { domReady } from 'utilities/dom';
import { Reporter, visibilityObserver } from 'features/dataCapture';
import { utagLinkPDP, utagLinkChat, getSkuFromUrl } from 'features/tealium';
import { setGenericEvent, setSearch } from 'features/tealium/ga4/custom';
import { openMessenger } from 'features/genesysCloud';

import initEmailValidation from './index';
// redirect if in an iframe
const isErrorPage = !!(utag_data.page_type == 'error_404' && !window.location.pathname.includes('s_'));
const isInIFrame = window.location !== window.parent.location;
if (isInIFrame) {

	window.location = '/error-iframe.aspx';

}

const initializeDataCapture = () => {

	if (!window.lp.globals.enableDataCapture || !window.lp.globals.canShowCertona) {

		return;

	}

	const { reporter } = window.lp.dataCapture;

	const elementObserver = visibilityObserver((element) => {

		const data = Reporter.getSkuViewData(element);
		reporter.track(data);

	});
	document.addEventListener('recommendedItems:loaded', () => {

		moreYouMayLikeGAEvents();
		if (isErrorPage) {

			const $recentlyViewedContainer = $('#recentlyViewedContainer');
			if ($recentlyViewedContainer) {

				recentlyViewGAEvents();
				$recentlyViewedContainer.addClass('recentlyMoreLikeThisLink');

			}

		}
		const elements = document.querySelectorAll(
			'#certonaItems img[data-sku], #moreYouMayLikeContainer img[data-sku]'
		);
		elements.forEach((element) => elementObserver.observe(element));

	});

};

const trackProductNotFound = (sku) => {

	utagLinkPDP()({
		eventAction: 'Product Unavailable - Page Not Found',
		eventLabel: sku,
		nonInteraction: 1
	});

};

// on doc ready
domReady(() => {

	// certona
	if (window.lp.globals.canShowCertona) {

		window.lp.certona.getRecommendedItems();

	}
	initEmailValidation();
	initializeDataCapture();

});

function moreYouMayLikeGAEvents () {

	const moreYouMayLikeItem = document.querySelectorAll('#moreYouMayLikeContainer .moreYouMayLikeItem');
	moreYouMayLikeItem.forEach(onClickGenerator('Click-More-You-May-Like'));

}

function emailSubscribeEvent () {

	const emailButton = document.querySelector('#emailSubscribeBtnError');
	if (emailButton) {

		emailButton.addEventListener('click', (e) => {

			utagLinkPDP('Error Page')({
				eventAction: 'Click-Subscribe-Email',
				eventLabel: `${window.location.pathname}`
			});

		});

	}

}
function viewCategoriesGAEvents () {

	const categoriesList = document.querySelectorAll('.hpBucketWrapper>a');

	categoriesList.forEach((link) => {

		link.addEventListener('click', (e) => {

			const eventLabel = link.innerText.replace('\n', ' ');
			utagLinkPDP('Error Page')({
				eventAction: 'Click-Top-Categories',
				eventLabel
			});

		});

	});

}
function recentlyViewGAEvents () {

	const viewAllLink = document.querySelector('.viewAllRecentlyViewedBtn');
	if (viewAllLink) {

		viewAllLink.addEventListener('click', () => {

			utagLinkPDP('Error Page')({
				eventAction: 'Click-Recent-View-All'
			});
			setGenericEvent({ event_name: 'rcntvw_viewall_click' });

		});

	}

	const recentlyViewedProdImgs = document.querySelectorAll('#recentlyViewedContainer a:first-child');
	recentlyViewedProdImgs.forEach(onClickGenerator('Click-Recently-Viewed-Product', 'recentlyViewedProdImg'));
	const moreLikeThisLinks = document.querySelectorAll('#recentlyViewedContainer .moreLikeThisLink');
	moreLikeThisLinks.forEach(onClickGenerator('Click-Recently-Viewed-Product', 'moreLikeThisLink'));

}

const onClickGenerator = (eventAction, type) => (element, i) => {

	element.addEventListener('click', (e) => {

		const sku = e.target.closest('.moreYouMayLikeItem ').querySelector('[data-certonasku]').dataset.certonasku;
		const eventLabel = `${i + 1}:${sku}`;
		utagLinkPDP('Error Page')({ eventAction, eventLabel });
		if (type === 'moreLikeThisLink') {

			setGenericEvent({ event_name: 'rcntvw_mlt_click', item_position_number: i + 1 });

		} else if (type === 'recentlyViewedProdImg') {

			setGenericEvent({ event_name: 'rcntvw_product_click', item_position_number: i + 1 });

		}

	});

};

window.addEventListener('load', () => {

	const sku = getSkuFromUrl(window.location.pathname);
	// if sku exists in the url, we know 404 is for product
	if (sku) {

		trackProductNotFound(sku);

	}

	if (window.lp.globals && window.lp.globals.isChatToggleOn) {

		const trackChatEvent = utagLinkChat();
		const chatButton = document.querySelector('#lpContainer .chatWrapperWithIcon button');
		let eventLabel = '';
		if (chatButton) {

			eventLabel = chatButton.dataset.gaLabel;
			chatButton.addEventListener('click', (el) => {

				openMessenger();
				trackChatEvent({
					eventAction: 'Request-Chat',
					eventLabel
				});

				setGenericEvent({
					event_name: 'chat_click',
					chat_button_type: 'static',
					chat_button_text: el.target.innerHTML
				});

			});

		}

		trackChatEvent({
			eventAction: 'Load-Chat',
			eventLabel,
			nonInteraction: 1
		});

	}
	viewCategoriesGAEvents();
	emailSubscribeEvent();

	// GA4 track no results found for search
	if (window.lp.pageData.showSearchResultNotFoundError) {

		setSearch({
			search_keyword: window.lp?.pageData?.searchTerm ?? '',
			search_provider: window.utag_data?.search_provider ?? '',
			search_filter: 'no',
			search_results: '0'
		});

	}

});
