import { getSearchTermFromUrl, isFilteredSearchResults } from 'features/search';
import queueUtagCall from 'features/tealium/queueUtagCall';

const setSearch = (params) => {

	const payload = {
		event_name: 'search',
		...params
	};

	if (!payload.search_keyword) {

		payload.search_keyword = getSearchTermFromUrl(window.location.href);

	}

	if (!payload.search_filter) {

		payload.search_filter = isFilteredSearchResults(window.location.pathname);

	}

	queueUtagCall(() => {

		// eslint-disable-next-line no-unused-vars
		const utagTrackingPromise = new Promise((resolve) => {

			// utag.link() returns true, or undefined if tracking is blocked
			const wasSuccessful = window.utag.link(payload, () => resolve());
			if (wasSuccessful) resolve();

			// Tracking is allowed 500ms for successful resolution,
			// then we resolve the promise regardless.
			setTimeout(() => resolve(), 500);

		});

	});

};

export default setSearch;
