/**
 * extract search term from url
 * @param  {string} url
 * @returns  {string} search term
 */
const getSearchTermFromUrl = (url) => {

	const searchMatch = url.match(/(?:\/)s_.+?\//);
	const searchTerm = searchMatch ? searchMatch[0].replace('s_', '').replace(/\//g, '').replace(/-/g, ' ') : '';
	return searchTerm;

};

export default getSearchTermFromUrl;
